'use client';

// Error boundaries must be Client Components

import React, { useEffect } from 'react';
import { Box, Card, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { IoClose } from '@shared/icons';
import { Button } from '@/components/ui/button';

export default function RouteError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error(error);
	}, [error]);

	const p = useTranslations('Page');

	return (
		<Card.Root>
			<Box textAlign="center" py={10} px={6}>
				<Box display="inline-block">
					<Flex
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						bg="red.500"
						rounded="50px"
						w="55px"
						h="55px"
						textAlign="center"
					>
						<IoClose size="30px" color="white" />
					</Flex>
				</Box>
				<Heading as="h2" size="xl" mt={6} mb={2}>
					{p('errorHeading')}
				</Heading>
				<Text color="gray.500" mb={4}>
					{p('errorText')}
				</Text>
				<Button
					colorPalette="green"
					variant="solid"
					size="lg"
					mt={6}
					onClick={reset}
					_hover={{ bg: 'green.600' }}
					_active={{ bg: 'green.700', transform: 'scale(0.95)' }}
					borderRadius="full"
				>
					{p('errorButtonText')}
				</Button>
			</Box>
		</Card.Root>
	);
}
